import type { RouteDefinition, RouteSectionProps } from "@solidjs/router";
import { createAsync } from "@solidjs/router";
import { getGeography } from "~/lib/fetcher";
import { Show } from "solid-js";
import { useEventsContext } from "~/contexts/EventsContext";
import { MetaTags } from "~/components/shared/MetaTags";
import { Geography } from "~/components/Geography/Geography";

export const route = {
  load({ params }) {
    void getGeography(params.id);
  },
} satisfies RouteDefinition;

export default function GeographyRoute(props: RouteSectionProps) {
  const geography = createAsync(() => getGeography(props.params.id), {
    name: "geography",
    deferStream: true,
  });

  const [, { setTemplate }] = useEventsContext();

  switch (geography()?.geography_level) {
    default:
    case "region":
      setTemplate("geography-1");
      break;
    case "department":
      setTemplate("geography-2");
      break;
    case "city":
      setTemplate("geography-3");
      break;
    case "neighborhood":
      setTemplate("geography-4");
      break;
  }

  const title = () => {
    if (geography()?.geography_level === "region") {
      switch (geography()?.programs?.length) {
        case 0:
          return `Immobilier neuf ${geography()!.name} : votre programme immobilier neuf ${geography()!.field_prefix_1}${geography()!.name}`;
        case 1:
          return `Immobilier neuf ${geography()!.name} : 1 programme immobilier neuf ${geography()!.field_prefix_1}${geography()!.name}`;
        default:
          return `Immobilier neuf ${geography()!.name} : ${geography()?.programs?.length} programmes immobiliers neufs ${geography()!.field_prefix_1}${geography()!.name}`;
      }
    } else if (geography()?.geography_level === "department") {
      const code = geography()?.field_code_area?.substring(0, 2);
      switch (geography()?.programs?.length) {
        case 0:
          return `Immobilier neuf ${geography()!.name} : votre programme immobilier neuf dans le ${code}`;
        case 1:
          return `Immobilier neuf ${geography()!.name} : 1 programme immobilier neuf dans le ${code}`;
        default:
          return `Immobilier neuf ${geography()!.name} : ${geography()?.programs?.length} programmes immobiliers neufs dans le ${code}`;
      }
    } else if (geography()?.geography_level === "city") {
      const code = geography()?.field_code_postal;
      switch (geography()?.programs?.length) {
        case 0:
          return `Immobilier neuf ${geography()!.name} : votre programme immobilier neuf à ${geography()!.name} (${code})`;
        case 1:
          return `Immobilier neuf ${geography()!.name} : 1 programme immobilier neuf à ${geography()!.name} (${code})`;
        default:
          return `Immobilier neuf ${geography()!.name} : ${geography()?.programs?.length} programmes immobiliers neufs à ${geography()!.name} (${code})`;
      }
    } else {
      return geography()?.metatag.find((m) => m.attributes?.name === "title")
        ?.attributes.content;
    }
  };

  const meta = () => {
    let m = geography()?.metatag.filter((m) => m.attributes.name !== "title");

    !m && (m = []);

    if (m) {
      m.push({
        tag: "meta",
        attributes: {
          name: "title",
          content: title()!,
        },
      });
    }

    return m;
  };

  return (
    <>
      <Show when={geography()}>
        <MetaTags tags={meta()} />
        <Geography geography={geography()!} />
      </Show>
    </>
  );
}
