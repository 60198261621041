import type { RouteDefinition, RouteSectionProps } from "@solidjs/router";
import { createAsync } from "@solidjs/router";
import { getJourney } from "~/lib/fetcher";
import { Show } from "solid-js";
import { useEventsContext } from "~/contexts/EventsContext";
import { MetaTags } from "~/components/shared/MetaTags";
import Journey from "~/components/Journey/Journey";

export const route = {
  load({ params }) {
    void getJourney(params.id);
  },
} satisfies RouteDefinition;

export default function JourneyRoute(props: RouteSectionProps) {
  const journey = createAsync(() => getJourney(props.params.id), {
    name: "journey",
    deferStream: true,
  });

  const [, { setTemplate }] = useEventsContext();
  setTemplate("journey");

  return (
    <>
      <Show when={journey()}>
        <MetaTags tags={journey()!.metatag} />
        <Journey journey={journey()!} />
      </Show>
    </>
  );
}
