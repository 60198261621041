import type { RouteDefinition, RouteSectionProps } from "@solidjs/router";
import { createAsync } from "@solidjs/router";
import { getWrapper } from "~/lib/fetcher";
import { Show, createEffect } from "solid-js";
import { ProgramStd } from "~/components/Program/ProgramStd";
import { isOutOfStock } from "~/utils/helper_program";
import { useEventsContext } from "~/contexts/EventsContext";
import { ProgramOutOfStock } from "~/components/Program/ProgramOutOfStock";
import { MetaTags } from "~/components/shared/MetaTags";
import { useRequestContext } from "~/contexts/RequestContext";

export const route = {
  load({ params }) {
    void getWrapper(Number(params.id));
  },
} satisfies RouteDefinition;

export default function ProgramRoute(props: RouteSectionProps) {
  const wrapper = createAsync(() => getWrapper(Number(props.params.id)), {
    name: "wrapper",
    deferStream: true,
  });

  const [, { setTemplate }] = useEventsContext();
  setTemplate("program-std");

  const [, { setWrapper: setProgramWrapper }] = useRequestContext();
  createEffect(() => {
    if (wrapper()) {
      setProgramWrapper!(wrapper()!);
    }
  });

  return (
    <>
      <Show when={wrapper()}>
        <MetaTags tags={wrapper()!.program.metatag} />
        <Show
          when={isOutOfStock(wrapper()!)}
          fallback={<ProgramStd wrapper={wrapper()!} />}
        >
          <ProgramOutOfStock wrapper={wrapper()!} />
        </Show>
      </Show>
    </>
  );
}
