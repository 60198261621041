import type { MenuItem, Settings } from "~/types/common";
import { Show } from "solid-js";
import MenuFooter from "~/components/shared/MenuFooter";
import { encodeStr, openEncodedUrl, urlRs } from "~/utils/url";
import { hasNoEscape } from "~/utils/no_escape";
import { ContactPhoneBtn } from "~/components/shared/ContactPhone";
import { useDrupalSettingsContext } from "~/contexts/DrupalSettingsContext";

import LogoAltarea from "~/img/logos/altarea.svg";
import LogoSourdlineWhite from "~/img/logos/sourdline-white-2023.svg";
import LogoFacebook from "~/img/logos/facebook.svg";
import LogoYoutube from "~/img/logos/youtube.svg";
import LogoInstagram from "~/img/logos/instagram.svg";
import LogoLinkedin from "~/img/logos/linkedin.svg";

import "./Footer.css";

type FooterProps = {
  menuItems: MenuItem[];
  settings: Settings;
};

export default function Footer(props: FooterProps) {
  let sourdLineAnchor: HTMLAnchorElement;
  const settingsContext = useDrupalSettingsContext();

  return (
    <>
      <footer
        class="footer-part"
        role="contentinfo"
        data-ga-zone="footer"
        data-test="footer"
      >
        <div class="baseline">
          <div class="altarea">
            <span>Une marque Altarea</span>
          </div>
          <div class="logo-altarea">
            <LogoAltarea />
          </div>
        </div>
        <div class="footer-2023">
          <div class="content-part with-background">
            <div class="flex-row">
              <ContactPhoneBtn eventZone="footer" />
              <div class="footer-content">
                <Show
                  fallback={
                    <ul data-test="menu" class="menu-footer">
                      <li class="grer-les-cookies">
                        <span
                          class="lnk"
                          onClick={() => {
                            // @ts-expect-error Didomi is injected at runtime
                            Didomi.preferences.show();
                          }}
                        >
                          Gérer les cookies
                        </span>
                      </li>
                      <li class="mentions-lgales">
                        <span
                          class="lnk"
                          onClick={() =>
                            openEncodedUrl(encodeStr("/mentions-legales.html"))
                          }
                        >
                          Mentions légales
                        </span>
                      </li>
                      <li class="donnes-personnelles">
                        <span
                          class="lnk"
                          onClick={() =>
                            openEncodedUrl(
                              encodeStr("/donnees-personnelles.html"),
                            )
                          }
                        >
                          Données Personnelles
                        </span>
                      </li>
                    </ul>
                  }
                  when={!hasNoEscape()}
                >
                  <MenuFooter
                    name="footer"
                    items={props.menuItems}
                    class="menu-footer"
                  />
                </Show>
              </div>
            </div>
            <Show when={!hasNoEscape()}>
              <div class="flex-bottom">
                <Show when={props.settings.sourdline_enabled}>
                  <a
                    ref={sourdLineAnchor!}
                    href="#"
                    onClick={() => {
                      sourdLineAnchor.href = atob(
                        "aHR0cHM6Ly9jb2dlZGltLnNvdXJkbGluZS5jb20v",
                      );
                    }}
                    target="_blank"
                    class="sourdline"
                    data-test="sourdline"
                    title="Espace sourd"
                  >
                    <LogoSourdlineWhite />
                  </a>
                </Show>
                <div class="social">
                  <a
                    href="https://fr-fr.facebook.com/cogedim/"
                    target="_blank"
                    class="facebook-icon"
                    data-test="facebook"
                  >
                    <LogoFacebook />
                    Facebook
                  </a>
                  <a
                    href="https://www.youtube.com/user/CogedimLogement"
                    target="_blank"
                    class="youtube-icon"
                    data-test="youtube"
                  >
                    <LogoYoutube />
                    Youtube
                  </a>
                  <a
                    href="https://www.instagram.com/cogedim/"
                    target="_blank"
                    class="instagram-icon"
                    data-test="instagram"
                  >
                    <LogoInstagram />
                    Instagram
                  </a>
                  <a
                    href="https://fr.linkedin.com/company/cogedim"
                    target="_blank"
                    class="linkedin-icon"
                    data-test="linkedin"
                  >
                    <LogoLinkedin />
                    LinkedIn
                  </a>
                </div>
              </div>
            </Show>
          </div>
          <div class="content-part" data-test="legals">
            <Show when={props.settings.import_last_date}>
              <p class="last-update" data-test="last-update">
                Date de mise à jour : {props.settings.import_last_date}
              </p>
            </Show>
            <p class="legals" data-test="legals-main-invest">
              Comme tout investissement, investir en Pinel / Pinel + ou en LMNP
              peut présenter des risques. L'équilibre économique de l'opération
              de défiscalisation dépend principalement du marché locatif, des
              charges liées à la détention d'un bien en vue de sa location et de
              la fiscalité immobilière.{" "}
              <a
                href={urlRs(
                  "journeys",
                  "/conseils-experts/risques-lies-a-linvestissement-pinel-lmnp-bouvard.html",
                )}
              >
                En savoir plus.
              </a>
            </p>

            <div id="legals-offer-receiver" />

            <Show when={settingsContext.escda_enabled}>
              <p class="legals">
                *Catégorie Promotion immobilière - Étude BVA – Viséo CI – Plus
                d’infos sur{" "}
                <a href="http://www.escda.fr" target="_blank">
                  escda.fr
                </a>
                .
              </p>
            </Show>
          </div>
        </div>
      </footer>
    </>
  );
}

/*
  <div class="content-part" data-test="legals">
    {% if legals|render|trim %}
        <p class="legals">{{ legals|raw }}</p>
    {% endif %}
    {% if offer_legals %}
        <p class="legals">{{ offer_legals|raw }}</p>
    {% endif %}
    {% if route_name == 're_pinel.results' %}
        <p class="legals">Cette simulation est confidentielle et sans engagement. Dans le cadre de loi Pinel / Pinel +, le non-respect des engagements de location entraîne la perte des incitations fiscales. Les chiffres ci-dessus sont donnés à titre indicatif sur la base des données personnelles fournies. Les résultats ne peuvent donc être garantis. Elle ne constitue en aucune façon un engagement de financement et devra être approfondie par un conseiller spécialisé en financement immobilier. * La durée d'amortissement de la TVA étant de 20 ans, le contribuable qui vendrait son logement avant la fin de cette période se verrait dans l'obligation de restituer à l'administration fiscale la part de TVA non amortie. Celle-ci calculée au prorata du nombre d'années restant à courir, sauf si la cession du logement se fait en direction d'un investisseur qui reprendrait à son compte le bail commercial.</p>
    {% endif %}


  </div>
 */
